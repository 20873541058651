import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import CloseIcon from "assets/svgs/close-btn.svg";
import RichText from "components/rich-text";
import getImagePath from "helpers/get-image-path";

import {
  customerCookWare, customerCookwareBody, customerCookwareText,
  customerCookwareImage, modalClose,
  customerCookwareContent , customerCookwareImageDesktop,
  customerCookwareImageMobile, modalCloseResponsive,
  modalCloseDesktop, customServiceModal, modalContainer
} from "./style.mod.scss";

const CustomerCookware = ({ data, children }) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const mData = data?.modal && data.modal[0];
  if (!data || !mData) return <></>;

  return (
    <div className={customerCookWare}>
      <div className={modalContainer} onClick={handleShow}>
        { children }
      </div>

      <Modal show={show} onHide={handleClose} className={customServiceModal}>
        <CloseIcon className={`${modalClose} ${modalCloseDesktop}`} onClick={handleClose} />
        <Modal.Body>
          <CloseIcon className={`${modalClose} ${modalCloseResponsive}`} onClick={handleClose} />
          <div className={customerCookwareBody}>
            <div className={customerCookwareContent} >
              <div className={customerCookwareText}>
                <RichText
                  blocks={mData.content}
                />
              </div>
              <div className={customerCookwareImage}>
                <img className={customerCookwareImageMobile} srcSet={getImagePath(mData.image, true)} alt={mData?.image?.alt} />
                <img className={customerCookwareImageDesktop} srcSet={getImagePath(mData.image, true)} alt={mData?.image?.alt} />
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default CustomerCookware;
