// extracted by mini-css-extract-plugin
export var customServiceModal = "style-mod--custom-service-modal--1J5Mj";
export var modalClose = "style-mod--modal-close--1V1V3";
export var modalCloseDesktop = "style-mod--modal-close-desktop--OhYJ5";
export var modalCloseDesktopDesktop = "style-mod--modal-close-desktop-desktop--PNTNw";
export var modalCloseDesktopResponsive = "style-mod--modal-close-desktop-responsive--TLX8a";
export var modalCloseResponsive = "style-mod--modal-close-responsive--2gn3t";
export var modalParagraph = "style-mod--modal-paragraph--3XZh-";
export var modalTitle = "style-mod--modal-title--iDTJ9";
export var customerCookwareBody = "style-mod--customer-cookware-body--DN_R0";
export var customerCookwareContent = "style-mod--customer-cookware-content--1jThf";
export var customerCookwareText = "style-mod--customer-cookware-text--fDejo";
export var customerCookwareImage = "style-mod--customer-cookware-image--1IAkU";
export var customerCookwareTextImage = "style-mod--customer-cookware-text-image--3RSPG";
export var customerCookwareTitle = "style-mod--customer-cookware-title--LU8wP";
export var customerCookwareParagraph = "style-mod--customer-cookware-paragraph--2eC_W";
export var customerCookwareImageMobile = "style-mod--customer-cookware-image-mobile--1Z5qv";
export var customerCookwareImageDesktop = "style-mod--customer-cookware-image-desktop--xBQTH";